import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoPage = () => (
    <Layout>
        <SEO title="kouluruoka.fi-mestari" description="Sinustako kouluruoka.fi-mestari?" />
        <h1 className='pageTitle'>Haluatko kouluruoka.fi-mestariksi?</h1>
        <p>
            Haemme nyt <i>kouluruoka.fi-mestareita</i>. Mestarin tehtävänä on syöttää oman koulun ruokalista kouluruoka.fi-palveluun.
            Tämä onnistuu helposti kouluruoka.fi:n hallintasivulta, johon kirjaudut sisään omilla tunnuksillasi.
            <br /><br />
            Kiitoksena työstäsi saat koulun päätyttyä keväällä 2024 kouluruoka.fi-mestari T-paidan ja työtodistuksen. Lisäksi saat halutessasi nimimerkkisi
            kouluruoka.fi:hin hallitsemasi ruokalistan sivulle.
            <br /><br />
            <i>Ollaksesi mestari, sinun tulee olla yli 13 vuotias. Tarvitset lisäksi luvan vanhemmiltasi jos olet alle 18 vuotias.</i>
            <br/><br/>
            <b>Kiinnostuitko?</b> Ota yhteyttä osoitteeseen <a href="mailto:mestari@kouluruoka.fi">mestari@kouluruoka.fi</a>.
            <br/><br/>
            <a href='/info/'>Tietosuojaseloste</a>
        </p>
    </Layout>
)

export default InfoPage


